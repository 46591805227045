@import 'ui-framework/ui';
@import 'bootstrap/scss/bootstrap';
@import './custom-bootstrap.scss';

body {
    background-color: #fff;
    color: $dark;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

#root,
.app-main {
    display: block;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
}

.app-main {
    color: inherit;
}

.fill,
.flex-1 {
    flex: 1;
}

.j-around {
    justify-content: space-around;
}
