@import 'ui-framework/ui';

:local(.container) {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .app-name {
        color: rgba($dark, 0.7);
        &:after {
            content: '';
            border-bottom: 2px solid rgba($dark, 0.7);
            width: 100px;
            display: block;
            margin: 0.5rem auto 0 auto;
        }
    }
    .app-demo {
        .images-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .demo-fade-enter {
            transform: scale(0.1);
            &.demo-fade-enter-active {
                transform: scale(1);
                transition: all 300ms ease-in;
            }
        }
        .demo-fade-leave {
            opacity: 1;
            &.demo-fade-leave-active {
                opacity: 0.01;
                transition: opacity 200ms ease-in;
            }
        }
    }
    .login-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0;
    }
    .modal-dialog {
        max-height: calc(90vh);
        max-width: 40vw;
        @include media-breakpoint-down(md, $grid-breakpoints) {
            max-width: 60vw;
        }
        @include media-breakpoint-down(sm, $grid-breakpoints) {
            max-width: 90vw;
        }
    }
    .modal-title {
        padding-top: 1rem;
    }
    .modal-body {
        padding: 1rem 30px;
        ol {
            padding-inline-start: 1rem;
        }
    }
    .modal-footer {
        border-top: none;
        padding: 0 30px 1rem;

        .btn {
            cursor: pointer;
        }

        .btn-cancel {
            margin-right: 1rem;
            @include media-breakpoint-down(md, $grid-breakpoints) {
                margin-right: 0.25rem;
            }
        }
    }
}
