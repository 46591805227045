@import 'ui-framework/ui';

:local(.container) {
    color: #fff;
    height: 80px;
    padding: 1rem;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    @include media-breakpoint-down(md, $grid-breakpoints) {
        padding: 1rem 0;
    }
    .header-logo {
        color: $dark;
        display: flex;
        align-items: center;
        text-decoration: none;
        .logo-image {
            height: 48px;
            width: 48px;
        }
        .logo-text {
            margin-bottom: 0;
            margin-left: 0.5rem;
            font-size: 16px;
            font-weight: $font-weight-bold;
        }
    }
    .header-buttons {
        display: flex;
        .btn {
            border-width: 2px;
        }
        .donate-button {
            margin-left: 0.5rem;
        }
        @include media-breakpoint-down(md, $grid-breakpoints) {
            .btn {
                border-width: 0;
                padding: 0;
                &:hover {
                    background-color: transparent;
                    color: $dark;
                }
            }
        }
        // @include media-breakpoint-down(md, $grid-breakpoints) {
        //     flex-direction: column;
        //     .btn {
        //         width: 120px;
        //     }
        //     .donate-button {
        //         margin-left: 0;
        //         margin-top: 0.5rem;
        //     }
        // }
    }
}
